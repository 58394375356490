const apimUrl = process.env.API_URL;
const baseUrl = process.env.API_URL + "/UserManagementApi";
const notificationUrl = process.env.API_URL + "/NotificationApi"

const adminApiBaseUrl = process.env.API_URL + "/adminservice"

export function checkMSSalesAccess() {
  return window.httpService
    .get(baseUrl + "/CheckMsSaleAccess")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function getMenuItems() {
  return window.httpService
    .get(baseUrl + "/GetUserResources")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function getFiscalYears() {
  return window.httpService
    .get(baseUrl + "/GetFiscalYears")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function gethelpLinks() {
  return window.httpService
    .get(adminApiBaseUrl + "/GetHelpLinks")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}


export function getNotifications() {
  return window.httpService
    .get(notificationUrl  + "/GetUserNotifications")
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}

export function getUserPrefApiUrl() {
  return window.urlInference.infer(`${apimUrl}/UserPreferenceAPI/`);
}