import { IconButton } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@material-ui/core/Menu';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import FlareIcon from "@material-ui/icons/Flare";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useState } from "react";
import * as menuApi from "../../api/menuApi";
import Chip from "@material-ui/core/Chip";
import { commonSettings } from "../../lib/spaLoader";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import * as Constants from "../../constants";
import FlashMessage from "../shared/FlashMessage";
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { OCVInAppFeedback } from "../OneCustomerVoice/ocvFeedback";
import { AlertSuccess } from "../shared/AlertToast";
import { withSnackbar } from "notistack";
import { getEnvironmentName } from "../shared/UtilityFunctions";
import NotificationsDialog from "./NoificationAlert";
import NotificationSideBar from "./NotificationSideBar";
import Notifications, { getfilteredNotification } from "./notificationsRepo";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  EnvironmentNameChip: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  profileAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    color: theme.palette.primary.contrastText
  },
  select: {
    color: theme.palette.primary.contrastText,
    "&:before": {
      borderColor: theme.palette.primary.contrastText
    },
    "&:after": {
      borderColor: theme.palette.primary.contrastText
    }
  },
  icon: {
    fill: theme.palette.primary.contrastText
  },
  appBarItems: {
	  marginTop: theme.spacing(0),
  },
}));

function Login(props) {
  const classes = useStyles();
  const userName = localStorage.getItem("user");
  const alias = userName.indexOf('@') >= 0 ? userName.substring(0, userName.indexOf('@')) : userName;
  const imgUrl = `https://who/photos/${alias}.jpg`;

  const [fiscalYear] = React.useState(localStorage.getItem(Constants.LAST_FISCAL_YEAR_SELECTED));
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
  const [openNotificationSideBar, setOpenNotificationSideBar] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  useEffect(() => {
    //fetch new notifications after every 20 minutes
    const interval = setInterval(() => {
      menuApi.getNotifications().then((data) => {
        Notifications.AllNotifications = data;
        setNotificationData(getfilteredNotification());
      })
    }, 1200000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  function fetchNotifications() {
    menuApi.getNotifications().then((data) => {
      Notifications.AllNotifications = data;
      setNotificationData(data);
      Notifications.DisabledKeys = [];
      setOpenNotificationPopup(true);
    });
  }

  function disableNotification(key) {
    Notifications.DisabledKeys.push(key);
    setNotificationData(getfilteredNotification());
  }

  
  let environmentName = getEnvironmentName();
  if (environmentName.includes("PROD")) {
    // don't show environment name in production
    environmentName = undefined;
  }


  const handleFiscalYearChange = (event) => {
    if (fiscalYear !== event.target.value) {
      localStorage.setItem(Constants.LAST_FISCAL_YEAR_SELECTED, event.target.value);
      window.location.reload();
    }
  };

  const handleThemeItemClick = () => {
    var activeTheme = ToggleTheme();
    props.setTheme(activeTheme);
    if (activeTheme === "Dark Theme") {
      commonSettings.applyTheme("DARKMODE");
    } else {
      commonSettings.applyTheme("LIGHTMODE");
    }
  };

  const ToggleTheme = () => {
    return localStorage.getItem("themeName") === "Dark Theme" ? "Default Theme" : "Dark Theme";
  };

  const showFeedbackAlert = () => {
    AlertSuccess(props.enqueueSnackbar, "Thank You for the Feedback!!");
  }
  const ocvInAppFeedback = new OCVInAppFeedback(showFeedbackAlert);

  if (process.env.HIDE_OCV !== 'true') {
    window.onkeyup = function (e) {
      // short cut CTRL + SHIFT + F
      if (e.ctrlKey === true && e.shiftKey === true && e.keyCode === 70) {
        ocvInAppFeedback.CaptureMultiFeedback();
      }
    };
  }

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => setOpenNotificationSideBar(!openNotificationSideBar)}>
        <IconButton
          aria-label="Notification"
          color="inherit"
          id="NotificationButton"
          className="custom-chip-style"
        >
          <Badge badgeContent={notificationData.length} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      {process.env.HIDE_OCV !== 'true' && (
      <MenuItem onClick={ocvInAppFeedback.CaptureMultiFeedback}>
        <IconButton
          aria-label="One Customer Voice"
          color="inherit"
          id="OCV"
          className="custom-chip-style"
        >
          <SentimentSatisfiedOutlinedIcon />
        </IconButton>
        <p>Feedback</p>
      </MenuItem>
      )}
      <MenuItem onClick={handleThemeItemClick}>
        <IconButton
          aria-label="Change Theme"
          color="inherit"
          id="ToggleThemeButton"
          className="custom-chip-style"
        >
          {localStorage.getItem("themeName") === "Dark Theme" ? <FlareIcon /> : <Brightness3Icon />}
        </IconButton>
        <p>Theme</p>
      </MenuItem>
      <MenuItem>
        <Avatar alt={userName} src={imgUrl} classes={classes.profileAvatar} className="custom-chip-style"/>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        {notificationData && notificationData.length > 0 &&
          <NotificationSideBar
            openNotifications={openNotificationSideBar}
            setOpenNotifications={setOpenNotificationSideBar}
            notificationData={notificationData}
            disableNotification={disableNotification}
            refreshNotifications={fetchNotifications}
          />}
        {notificationData && notificationData.length > 0 &&
          <NotificationsDialog
            openNotifications={openNotificationPopup}
            setOpenNotifications={setOpenNotificationPopup}
            notificationData={notificationData}
          />}
        <Grid container>
          <Grid item><FlashMessage /></Grid>
          <Grid item className={classes.appBarItems}>
            <FormControl 
            className={classes.formControl}
            tabindex='-1'>
              <Select
                labelId="demo-simple-select-label"
                id="select-fiscal-year"
                value={localStorage.getItem(Constants.LAST_FISCAL_YEAR_SELECTED)}
                onChange={handleFiscalYearChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}
              >
                {props.fiscalYears &&
                  props.fiscalYears.length > 0 &&
                  props.fiscalYears.map((item) => <MenuItem value={item.year} key={item.year}>{item.year}</MenuItem>)}
              </Select>
            </FormControl>

            {environmentName && (
              <Chip
                color="primary"
                label={environmentName}
                variant="outlined"
                className={classes.EnvironmentNameChip}
              />
            )}
          </Grid>
          <Grid item className={classes.appBarItems}>
            <IconButton
              aria-label="Notification"
              color="inherit"
              id="NotificationButton"
              onClick={() => setOpenNotificationSideBar(!openNotificationSideBar)}
              className="custom-chip-style"
              tabindex='0'
            >
              <Badge badgeContent={notificationData.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item  className={classes.appBarItems}>

            {process.env.HIDE_OCV !== 'true' && (
              <IconButton
                aria-label="One Customer Voice"
                color="inherit"
                id="OCV"
                onClick={ocvInAppFeedback.CaptureMultiFeedback}
                className="custom-chip-style"
                tabindex='0'
              >
                <SentimentSatisfiedOutlinedIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item className={classes.appBarItems}>
            <IconButton
              aria-label="Change Theme"
              color="inherit"
              onClick={handleThemeItemClick}
              id="ToggleThemeButton"
              className="custom-chip-style"
              tabindex='0'
            >
              {localStorage.getItem("themeName") === "Dark Theme" ? <FlareIcon /> : <Brightness3Icon />}
            </IconButton>
          </Grid>
          <Grid item className={classes.appBarItems}>
              <Avatar alt={userName} src={imgUrl} classes={classes.profileAvatar} className="custom-chip-style"/>
          </Grid>
        </Grid>
      </div>

	  <div className={classes.sectionMobile}>
        {renderMobileMenu}
        {notificationData && notificationData.length > 0 &&
          <NotificationSideBar
            openNotifications={openNotificationSideBar}
            setOpenNotifications={setOpenNotificationSideBar}
            notificationData={notificationData}
            disableNotification={disableNotification}
            refreshNotifications={fetchNotifications}
          />}
        {notificationData && notificationData.length > 0 &&
          <NotificationsDialog
            openNotifications={openNotificationPopup}
            setOpenNotifications={setOpenNotificationPopup}
            notificationData={notificationData}
          />}
        <Grid container>
          <Grid item>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="select-fiscal-year-mobile"
                value={localStorage.getItem(Constants.LAST_FISCAL_YEAR_SELECTED)}
                onChange={handleFiscalYearChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}
              >
                {props.fiscalYears &&
                  props.fiscalYears.length > 0 &&
                  props.fiscalYears.map((item) => <MenuItem value={item.year} key={item.year}>{item.year}</MenuItem>)}
              </Select>
            </FormControl>

          </Grid>
          <Grid item>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withSnackbar(Login);
