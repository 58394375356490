import React from "react";
import {
  mdiCheckAll,
  mdiAccountTie,
  mdiMicrosoftExcel,
  mdiAccountSettings,
  mdiChartDonutVariant,
  mdiFinance,
  mdiCircle,
  mdiBullseyeArrow,
  mdiEqualizer,
  mdiAccountMultiple,
  mdiTrendingUp,
  mdiAccountCog,
  mdiCogTransfer,
  mdiAtomVariant,
  mdiBookOpen,
  mdiCog,
  mdiBriefcaseCheck,
  mdiWrench 
} from "@mdi/js";
import Icon from "@mdi/react";

export const getIcon = (iconname, theme) => {
  if (iconname === "User Management")
    return (
      <Icon
        path={mdiAccountSettings}
        title="User Management"
        size={1}
        color={getIconColor(theme)}
      />
    );
   else if (iconname === "System Settings")
    return (
      <Icon
        path={mdiCog}
        title="System Settings"
        size={1}
        color={getIconColor(theme)}
      />
    );
  else if (iconname === "Distributed Quota")
    return (
      <Icon
        path={mdiBullseyeArrow}
        title="Distributed Quota"
        size={1}
        color={getIconColor(theme)}
      />
    );
  else if (iconname === "Home")
    return <Icon path={mdiFinance} title="Quota" size={1} color={getIconColor(theme)} />;
  else if (iconname === "Target Management")
    return (
      <Icon
        path={mdiChartDonutVariant}
        title="Target Management"
        size={1}
        color={getIconColor(theme)}
      />
    );
  else if (iconname === "Participant Management")
    return (
      <Icon
        path={mdiAccountTie}
        title="Participant Management"
        size={1}
        color={getIconColor(theme)}
      />
	);
	else if (iconname === "Self Serve")
    return (
      <Icon
        path={mdiCogTransfer}
        title="Self Serve"
        size={1}
        color={getIconColor(theme)}
      />
	);
	else if (iconname === "Participant Setup")
    return (
      <Icon
        path={mdiAccountCog}
        title="Participant Setup"
        size={1}
        color={getIconColor(theme)}
      />
	);
	else if (iconname === "Participant Sign Off")
    return (
      <Icon
        path={mdiCheckAll}
        title="Participant Sign Off"
        size={1}
        color={getIconColor(theme)}
      />
    );
  else if (iconname === "Modeler")
    return (
      <Icon path={mdiEqualizer} title="Quota Modeller" size={1} color={getIconColor(theme)} />
    );
  else if (iconname === "Non-Distributed Quota")
    return (
      <Icon
        path={mdiMicrosoftExcel}
        title="Non-Distributed Quota"
        size={1}
        color={getIconColor(theme)}
      />
    );
  else if (iconname === "Sign Off")
    return <Icon path={mdiCheckAll} title="Sign Off" size={1} color={getIconColor(theme)} />;
  else if (iconname === "Partner Quota")
    return (
      <Icon path={mdiAccountMultiple} title="Partner Quota" size={1} color={getIconColor(theme)} />
    );
  else if (iconname === "Reports")
    return <Icon path={mdiTrendingUp} title="Reports" size={1} color={getIconColor(theme)} />;
  else if (iconname === "Simulator")
    return <Icon path={mdiAtomVariant} title="Simulator" size={1} color={getIconColor(theme)} />;
    else if (iconname === "Compensable Metrics" || iconname === "Target Input")
      return <Icon path={mdiBookOpen} title="Compensable Metrics" size={1} color={getIconColor(theme)} />;
      else if (iconname === "Business Review")
       return <Icon path={mdiBriefcaseCheck} title="Business Review" size={1} color={getIconColor(theme)} />;
       else if (iconname === "Adjustments")
       return <Icon path={mdiWrench} title="Adjustments" size={1} color={getIconColor(theme)} />;
  return <Icon path={mdiCircle} title="icon" size={1} color={getIconColor(theme)} />;
};

export const getIconColor = (theme) => {
  return theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.common.white;
};

export const getEnvironmentName = () => {
  let environmentName = process.env.ENVIRONMENT_NAME;
  if (!environmentName) {
    environmentName = window.location.href.includes("dev") || window.location.href.includes("localhost")
      ? "DEV"
      : window.location.href.includes("uat")
        ? "UAT"
        : "PROD";
  } else {
    environmentName = environmentName.toUpperCase();
    if (environmentName === "PRD") {
      // for consistency with code that uses window.location.href
      environmentName = "PROD";
    }
  }
  return environmentName;
}
