import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ErrorOutlineTwoToneIcon from "@material-ui/icons/ErrorOutlineTwoTone";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 500,
    margin: 30
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

function CustomError(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="error" className={classes.avatar}>
            <ErrorOutlineTwoToneIcon></ErrorOutlineTwoToneIcon>
          </Avatar>
        }
        title={props.errorObj.name}
        subheader={new Date().toString()}
      />
      <CardContent>
        <Typography variant="body2" color="error" component="p">
          {props.errorObj.message}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CustomError;
