import { CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleRouterEvent, getMountedApps } from "../../lib/spaLoader";
import Header from "../header/Header";
import LoadSpinner from "../shared/LoadSpinner";
import SideBar from "../sidebar/Sidebar";
import Grid from "@material-ui/core/Grid";
import themes from "../../palette";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SplashHome from "../splashhome/splashHome";
import Help from "../app/Help/Help";

function App(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [header, setHeader] = React.useState("");
  const [showHome, setShowHome] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [theme, setTheme] = useState(props.lastActiveTheme);
  const [isShowHelp, setIsShowHelp] = useState(false);

  // Function that changes theme of the Shell and all the microfront ends
  const changeTheme = (themeName) => {
    if (themeName === "Dark Theme") {
      setTheme(themes.dark);
      localStorage.setItem("themeName", "Dark Theme");
    } else {
      setTheme(themes.blue);
      localStorage.setItem("themeName", "Default Theme");
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0.75),
      paddingTop: theme.spacing(8)
    },
    flexibleGrid: {
      display: "contents !important"
    },
    success: { backgroundColor: "#2E7D32" },
    error: { backgroundColor: "#D10000" },
    warning: { backgroundColor: "#79791B" },
    info: { backgroundColor: "#651FFF" }
  }));
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSetHeader = (title) => {
    setHeader(title);
    setShowLoader(true);
    handleRouterEvent(title, setShowLoader);
    setMobileOpen(false);
    localStorage.setItem("lastVisitedApp", title);
  };

  function showErrorToast(error) {
    return toast.error(error, { autoClose: false });
  }

  React.useEffect(() => {
    const resources = props.resources;
    // if (resources && resources.length > 0) {
    //   const appToLoad = getAppToLoad(resources);
    //   setShowLoader(true);
    //   navigateToResource(appToLoad);
    //   handleRouterEvent(appToLoad, setShowLoader);
    // }
    let showHome = true;
    resources.forEach((element) => {
      showHome = showHome && !window.location.href.includes(element.resourceName);
    });

    setShowHome(showHome);
  }, [props.resources]);

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <MuiThemeProvider theme={theme || themes.blue}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={10}
        preventDuplicate
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        action={(key) => (
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
            <CloseIcon style={{ fontSize: "20" }} />
          </IconButton>
        )}
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info
        }}
        autoHideDuration={4000}
      >
        <div>
          <ToastContainer autoClose={3000} hideProgressBar />
          {props.error ? (
            showErrorToast(props.error)
          ) : (
            <div className={classes.root}>
              <CssBaseline />
              <Grid container className={classes.flexibleGrid}>
                <Grid container className={classes.flexibleGrid}>
                  <Header
                    onDrawerToggle={handleDrawerToggle}
                    header={header}
                    isdrawerOpen={mobileOpen}
                    setTheme={changeTheme}
                    fiscalYears={props.fiscalYears}
                  />
                </Grid>
                <Grid container className={classes.flexibleGrid}>
                  <SideBar
                    mobileOpen={mobileOpen}
                    onDrawerToggle={handleDrawerToggle}
                    onHeaderChange={handleSetHeader}
                    menuItems={props.menuItems}
                    setIsShowHelp={setIsShowHelp}
                  />
                  <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <LoadSpinner showLoader={showLoader} />
                    {getMountedApps().length === 0 && showHome && !showLoader && !isShowHelp && (
                      <SplashHome menuItems={props.menuItems} setShowHome={setShowHome} />
                    )}
                    {isShowHelp && <Help />}
                    {!isShowHelp &&
                      props.resources &&
                      props.resources.length > 0 &&
                      props.resources.map((application) => {
                        const applicationLCase = application.resourceName.toLowerCase();
                        return (
                          <div
                            key={applicationLCase}
                            id={applicationLCase}
                            className={applicationLCase}
                          />
                        );
                      })}
                  </main>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default App;
