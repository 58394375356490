import { DialogContent } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {
    makeStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";
import React from "react";
import themes from "../../palette";
import ReadMore from "../shared/ReadMore";

function NotificationsDialog(props) {
    const currentTheme = localStorage.getItem("themeName") === "Dark Theme" ? themes.dark : themes.blue;
    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: "relative",
            backgroundColor: localStorage.getItem("themeName") === "Dark Theme"
                ? currentTheme.palette.background.default : currentTheme.palette.background.main,
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
            color: currentTheme.palette.common.white,
        },
        wrapper: {
            backgroundColor: currentTheme.palette.background.default,
        },
    }));

    const classes = useStyles();
    const notificationData = props.notificationData.filter(a => a.isAlert === true);

    return (
        <ThemeProvider theme={currentTheme}>
                    <Dialog
                        open={props.openNotifications && notificationData.length > 0}
                        onClose={() => props.setOpenNotifications(false)}
                        aria-labelledby="notification-dialog"
                        aria-describedby="notification-mintstudio-dialog"
                        style={{ padding: 0 }}
                        fullWidth={true}
                        maxWidth="md"
                    >
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h6" className={classes.title}>
                                    Alerts
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        props.setOpenNotifications(false);
                                    }}
                                    aria-label="close"
                                    size="small"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <br />
                        <DialogContent p={0}>
                                {notificationData && notificationData.map((element, indx) =>
                                    <ReadMore key={indx} element={element} length={500} font={15} boxShadow={2}/>
                                )}
                        </DialogContent>
                    </Dialog>
        </ThemeProvider>
    );
}

export default withSnackbar(NotificationsDialog);