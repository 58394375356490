var resources = [];

// Function that creates resources collection from menuitems response
export function getResourcesFromMenuItems(menuItems) {
  menuItems.forEach((element) => {
    if (element.children.length > 0) {
      getResourcesFromMenuItems(element.children);
    }
    if (element.children.length === 0) {
      resources.push({
        resourceName: element.pathName,
        isStoreEnabled: element.isStoreEnabled,
      });
    }
  });
  return resources;
}
