import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { getInstrumentationKey } from './api/telemetryApi';
/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    state = {
        initialized: false
    };

    initialize = (instrumentationKey, browserHistory) => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided')
        }
        
        window.reactPlugin = new ReactPlugin();
        
        const aiConfig = {
            instrumentationKey: instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            isBrowserLinkTrackingEnabled: true,
            enableAjaxErrorStatusText: true,
            enableRequestHeaderTracking: true,
            enableUnhandledPromiseRejectionTracking: true,
            distributedTracingMode: DistributedTracingModes.W3C,
            extensions: [window.reactPlugin],
            extensionConfig: {
                [window.reactPlugin.identifier]: {
                    history: browserHistory
                }
            }
        };

        if (process.env.AI_ENDPOINT) {
            aiConfig.endpointUrl = process.env.AI_ENDPOINT;
        }
        window.appInsights = new ApplicationInsights({
            config: aiConfig
        });
        
        window.appInsights.loadAppInsights();
        window.appInsights.setAuthenticatedUserContext(localStorage.getItem("username")+"-"+window.httpService.fiscalYear);
        window.appInsights.GetCustomDimensions = (customProperties) => {
            return {
                ...customProperties,
                userAlias:window.localStorage.getItem("username"),
                fiscalYear:window.httpService.fiscalYear
            }
        }
    };
    
    componentDidMount() {
        const {history} = this.props;
        const {initialized} = this.state;

        getInstrumentationKey()
        .then(res => {
            let instrumentationKey = res.ikey;
            if (!Boolean(initialized) && Boolean(instrumentationKey) && Boolean(history)) {
                this.initialize(instrumentationKey, history);
                this.setState({initialized: true});
            }
        })
        .catch(err => {
            throw new Error("Failed to setup telemetry provider");
        })
        
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withRouter(withAITracking(window.reactPlugin, TelemetryProvider));
