const GET_INSTRUMENTATION_KEY = "https://onemintapidev.azure-api.net/TelemetryAPI/GetInstrumentationKey"

export function getInstrumentationKey(){
	if (process.env.AI_KEY) {
        return new Promise((resolve) => {
            resolve({
                ikey: process.env.AI_KEY
            });
        });
    }
	
    return window.httpService
    .get(GET_INSTRUMENTATION_KEY)
    .then(window.httpService.handleSuccess)
    .catch(window.httpService.handleError);
}