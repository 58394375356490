import { getEnvironmentName } from "../shared/UtilityFunctions";

export class OCVInAppFeedback {
    constructor(showFeedbackAlert) {
		
		var userName = localStorage.getItem('username');
        var userEmaiId = userName.indexOf('@') >= 0 ? userName : userName + '@microsoft.com';

		var environment = getEnvironmentName().includes("PROD") ? 0 : 1; // 1 is for test and 0 is for Production
		let initOptions  = {
			appId: 2426, // Replace by your OCV onboarded App Id
			stylesUrl: process.env.PUBLIC_STATIC_ASSET_URL + process.env.PUBLIC_STATIC_ASSET_DIR + "/officebrowserfeedback_vertical.css", // Replace by where you have hosted the .css
			intlUrl: process.env.PUBLIC_STATIC_ASSET_URL + process.env.PUBLIC_STATIC_ASSET_DIR + "/OCV/", // Replace by where you have hosted the intl files.
			environment:  environment,
			primaryColour: "#0078d7",
			secondaryColour: "#01589D",
			locale: "Mint Studio",
			bugForm: false,
			userEmail: userEmaiId,
			userEmailConsentDefault: true,
			onError: function onError(err) {
				console.log("Office InAppFeedback SDK encountered error.", { err });
			},
			onDismiss: function (success) {
				if (success) {
					showFeedbackAlert();
				}
			},
			telemetryGroup: {
				featureArea: "Mint Studio"  //You can also add sub-area here instead
			}
		};
		if(window.OfficeBrowserFeedback !== undefined){
			window.OfficeBrowserFeedback.initOptions = initOptions;
		}
    }

    /*
    This launches a Multiple feedback window
    */
    CaptureMultiFeedback = () => {
		this.handlePromise(window.OfficeBrowserFeedback.multiFeedback());
    }

    /*
    This sends a single feedback
    Feedback Type -> Smile, Frown or Bug
    */
    CaptureSingleFeedback = (feedbackType) => {
		this.handlePromise(window.OfficeBrowserFeedback.singleFeedback(feedbackType));
    }

    // function which logs promise fulfillment/rejection
    handlePromise = function (promise) {
        promise
            .then(
                function onFulfilled() {
                }
            )
            .catch(
                function onRejected(err) {
                    console.log("OCVService received error:", { err });
                }
            );
    }
}