/** @format */
import React from "react";
import "./FlashMessage.css";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { getEnvironmentName } from "./UtilityFunctions";


function FlashMessage(props) {

  const environmentName = getEnvironmentName();
  const bannerInfo = environmentName.includes("PROD")
    ? "Production"
    : "Non-Prod Environment";

  return (
    environmentName && (
      <span className="blinking">
        <Paper
          elevation={3}
          style={{
            paddingTop: "2px",
            paddingBottom: "2px",
            paddingLeft: "5px",
            paddingRight: "5px",
            backgroundColor: "greenyellow"
          }}
        >
          <Typography component="body1" style={{ color: "black", fontSize: "1.5rem", fontWeight: "bold" }}>
            {bannerInfo}
          </Typography>
        </Paper>
      </span>
    )
  );
}

export default FlashMessage;
