import React from "react";
import SplashCard from "../shared/splashCards";
import Grid from "@material-ui/core/Grid";

function SplashHome(props) {
  return (
    <React.Fragment>
      <section id="banner">
        <h1>Welcome to Mint Studio</h1>
        <p>A One-Stop Solution For All MINT Operations</p>
      </section>
      <br></br>
      <Grid container spacing={3}>
        {props.menuItems.map((element, index) => {
          return (
            <Grid item key={index} lg={3} md={3} sm={12} xs={12} style={{ textAlign: "center" }}>
              <SplashCard
                isParent={element.children && element.children.length > 0}
                name={element.title}
                children={element.children}
                pathName={element.pathName}
                setShowHome={props.setShowHome}
              ></SplashCard>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default SplashHome;
