import { createMuiTheme } from "@material-ui/core/styles";

const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
    }
});

const BlueTheme = createMuiTheme({
    palette: {
        type: "light",
        secondary: {
            main: '#a51d4d'
        }
    }
});

export default {
  dark: darkTheme,
  blue: BlueTheme,
};
