import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { navigateToResource } from "../../lib/spaLoader";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { getIcon } from "../shared/UtilityFunctions";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default function SplashCard(props) {
  const theme = useTheme();
  const classes = useStyles();

  const handleCardClick = (event) => {
    if (!props.isParent) {
      props.setShowHome(false);
      navigateToResource(props.pathName);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Card className={classes.root} onClick={handleCardClick}>
        <CardActionArea className="custom-chip-style">
          {/*  */}
          <CardContent>
            {getIcon(props.name, theme)}
            <Typography gutterBottom variant="h5" component="h2">
              {props.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      {props.isParent && (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.children &&
            props.children.map((element, index) => {
              return (
                <StyledMenuItem key={index}
                  onClick={() => {
                    props.setShowHome(false);
                    navigateToResource(element.pathName);
                  }}
                >
                  <ListItemIcon>{getIcon(element.title, theme)}</ListItemIcon>
                  <ListItemText primary={element.title} />
                </StyledMenuItem>
              );
            })}
        </StyledMenu>
      )}
    </React.Fragment>
  );
}
