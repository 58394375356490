import {
    makeStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from '@material-ui/icons/Refresh';
import React from "react";
import themes from "../../palette";
import Grid from "@material-ui/core/Grid";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Typography, Box } from "@material-ui/core";
import ReadMore from "../shared/ReadMore";

function NotificationSideBar(props) {
    const currentTheme = localStorage.getItem("themeName") === "Dark Theme" ? themes.dark : themes.blue;
    const useStyles = makeStyles({
        list: {
            width: 350,
        },
        appBar: {
            position: "relative",
            backgroundColor: localStorage.getItem("themeName") === "Dark Theme" ?
                currentTheme.palette.background.default : currentTheme.palette.primary.main,
            color: localStorage.getItem("themeName") === "Dark Theme" ?
                currentTheme.palette.background.main : currentTheme.palette.background.paper,
        },
        icon: {
            paddingLeft:0,
            paddingBottom:0,
            paddingRight:5,
            paddingTop:5,
        }
    });

    const classes = useStyles();

    return (
        <ThemeProvider theme={currentTheme}>
            <div className={classes.wrapper}>
                <div className="shell">
                    <SwipeableDrawer
                        anchor={"right"}
                        open={props.openNotifications}
                        onOpen={() => {}}
                        onClose={() => props.setOpenNotifications(false)}
                    >
                        <Box className={classes.appBar} p={1}>
                            <Grid container spacing={1}>
                                <Grid item sm>
                                    <Typography variant="h6" >
                                        <b> Notifications </b>
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            props.refreshNotifications();
                                        }}
                                        aria-label="close"
                                        size="small"
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item sm={1}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => {
                                            props.setOpenNotifications(false);
                                        }}
                                        aria-label="close"
                                        size="small"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        <div className={classes.list}>
                            {props.notificationData && props.notificationData.map((element, indx) =>
                                <Box key={indx} boxShadow={1} display="flex">
                                    <ReadMore element={element} length={140} font={12} boxShadow={0}/>
                                    <Box width="auto">
                                        <IconButton className={classes.icon} onClick = {() => {props.disableNotification(element.key)}}>
                                            <CloseIcon style={{ align: "top", fontSize: '12' }}/>
                                        </IconButton>
                                    </Box> 
                                </Box>
                            )}
                        </div>
                    </SwipeableDrawer>
                </div>
            </div>
        </ThemeProvider >
    );
}

export default withSnackbar(NotificationSideBar);