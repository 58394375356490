import { IconButton, Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Login from "./Login";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Link from "@material-ui/core/Link";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    background:
      theme.palette.type === "dark" ? theme.palette.background.default : theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1)
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  HomeLink: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    fontSize: "1.25rem",
    fontWeight: "500",
  }
}));

function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const trigger = useScrollTrigger();

  const handleDrawerOpen = () => {
    props.onDrawerToggle();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const idsToSkipTabIndex = ['mint-studio-title',
        'NotificationButton', 'OCV',
        'ToggleThemeButton', 'select-fiscal-year'];
      idsToSkipTabIndex.forEach((id, index) => {
        if (document.getElementById(id)) {
          document.getElementById(id).setAttribute('tabIndex', -1);
        }
      })
    }
  }

  const handleTabIndex = () => {
    const idsToSkipTabIndex = ['mint-studio-title',
      'NotificationButton', 'OCV',
      'ToggleThemeButton', 'select-fiscal-year'];
    idsToSkipTabIndex.forEach((id, index) => {
      if (document.getElementById(id)) {
        document.getElementById(id).setAttribute('tabIndex', 0);
      }
    })
  }

  const setTheme = (themeName) => {
    props.setTheme(themeName);
  };

  useEffect(() => {
    if (!props.isdrawerOpen)
      handleTabIndex();
  }, [props.isdrawerOpen]);

  return (
    <header>
      <div>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar variant={isSmallScreen ? "dense" : "regular"}>
              <IconButton
                className="custom-chip-style"
                color="inherit"
                aria-label="Hamburger Main Menu"
                onClick={handleDrawerOpen}
                edge="start"
                aria-expanded={props.isdrawerOpen ? true : false}
                onKeyDown={handleKeyDown}

              >
                {props.isdrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
              <Link href="/#/" id='mint-studio-title'>
                <Typography variant="h1" noWrap className={classes.HomeLink}>
                  Mint Studio
                </Typography>
              </Link>
              <Login fiscalYears={props.fiscalYears} setTheme={setTheme} />
            </Toolbar>
          </AppBar>
        </Slide>
      </div>
    </header>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired
};

export default Header;
