import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export default function GenericDialog(props) {
  return (
    <div>
      <Dialog
        open={props.isOpen || false}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionProps={{role:"none"}}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        {props.dismissable && (
          <DialogActions>
            <Button onClick={e => props.handleClose(e)} color="primary">
              {props.cancelText}
            </Button>
            <Button onClick={e => props.handleOk(e)} color="primary" autoFocus>
              {props.okText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
