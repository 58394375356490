export const AlertSuccess = (enqueueSnackbar, message) => {
    enqueueSnackbar(message, { variant: "success" }, { backgroundColor: 'red' });
};
  
export const AlertError = (enqueueSnackbar, message) => {
    enqueueSnackbar(message, { variant: "error" });
};
  
export const AlertInfo = (enqueueSnackbar, message) => {
    enqueueSnackbar(message, { variant: "info" });
};
  
export const AlertWarning = (enqueueSnackbar, message) => {
    enqueueSnackbar(message, { variant: "warning" });
};
  