import { toast } from "react-toastify";
import * as singleSpa from "single-spa";
import { bootstrapUserPreference } from "@mintstudiouicore/coreutil/userPreference/userPreferenceRepo";
import { getUserPrefApiUrl } from "../api/menuApi";

/*eslint-disable no-undef*/
export function hashPrefix(prefix) {
  return function (location) {
    return location.hash.startsWith(`#${prefix}`);
  };
}

export let commonSettings = {
  themeEventSubscribers: [],
  applyTheme: function (themeName) {
    this.themeEventSubscribers.forEach((ts) => {
      ts(themeName);
    });
  },
  registerThemeEventSubscriber: function (fun) {
    this.themeEventSubscribers.push(fun);
  }
};

export async function loadApp(name, hash, appURL, storeURL, commonSettings, TelemetryLogger) {

  TelemetryLogger.initializeTelemetry();
  let storeModule = {};
  let customProps =
  {
    globalEventDistributor: commonSettings,
    TelemetryLogger: TelemetryLogger,
    userPref: bootstrapUserPreference(getUserPrefApiUrl(), name)
  };
  const random = Math.random();
  try {
    storeModule = storeURL
      ? await SystemJS.import(`${storeURL}?random=${random}`)
      : { storeInstance: null };
  } catch (e) {
    toast.error(`Could not load store of app ${name}.`, { autoClose: 2000 });
    console.log(`Could not load store of app ${name}.`, e);
  }

  if (storeModule.default) {
    customProps.store = storeModule.default;
  }

  // register the app with singleSPA and pass a reference to the store of the app as well as a reference to the globalEventDistributor
  singleSpa.registerApplication(
    name,
    () => SystemJS.import(`${appURL}?random=${random}`),
    hashPrefix(hash),
    customProps
  );
}

export function getMountedApps() {
  return singleSpa.getMountedApps();
}

export function navigateToResource(resource) {
  if (resource) {
    singleSpa.navigateToUrl(`#/${resource}`);
  }
}

export function handleRouterEvent(app, setLoader) {
  const appStatus = singleSpa.getAppStatus(app.replace(/\s/g, "").toLowerCase());
  if (appStatus === "NOT_LOADED") {
    window.addEventListener("single-spa:app-change", () => {
      setLoader(false);
    });
  } else {
    setLoader(false);
  }
}

export async function registerMicroFrontEnd(resources, TelemetryLogger) {
  if (resources && resources.length > 0) {

    //create an instance of telemetry service
    const loadingPromises = [];
    const cdnEnv = ""; //Use this to target specific blob envs.

    let cdnHostUrl = window.urlInference.getCdnHostUrl(cdnEnv) || process.env.ASSET_URL;
    if (!cdnHostUrl.endsWith('/')) {
      cdnHostUrl = cdnHostUrl + '/';
    }

    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      const resourceName = resource.resourceName.toLowerCase();
      const resourceScriptUrl = `${cdnHostUrl}${resourceName}${process.env.BLOB_URL_APPEND
        }`;
      // const resourceScriptUrl = 'http://localhost:5000/build/static/js/singleSpaEntry.js';
      const resourceStoreUrl = resource.isStoreEnabled
        ? `${cdnHostUrl}${resourceName}/static/js/store.js`
        : undefined;
      // const resourceStoreUrl = 'http://localhost:5000/build/static/js/store.js';
      loadingPromises.push(
        loadApp(
          resourceName,
          `/${resourceName}`,
          resourceScriptUrl,
          resourceStoreUrl,
          commonSettings,
          TelemetryLogger
        )
      );
    }

    await Promise.all(loadingPromises);

    singleSpa.start();
  } else {
    // Properly handle this in future with app insights integration.
    toast.error("Fatal error. Resources array is empty.", { autoClose: false });
    console.error("Fatal error. Resources array is empty");
  }
}
