import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

function ReadMore(props) {

    const { element, length, font, boxShadow } = props;

    const useStyles = makeStyles(theme => ({
        button: {
            display: 'inline-block',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            marginLeft: 'auto',
            textAlign: "right",
        },
        box: {
            paddingLeft:10,
            paddingBottom:10,
            paddingRight:0,
            paddingTop:10,
        }
    }));

    const classes = useStyles();
    const [showMore, setShowMore] = useState(false);
    function getdisplayText() {
        if (showMore || element.title.length + element.data.length < length)
            return element.data;
        else
            return element.data.slice(0, Math.max(0, length - element.title.length)) + "....";
    };

    return (
            <Box className={classes.box} boxShadow={boxShadow} flexGrow={1} p={1}>
                <div style={{ alignContent:"space-between", display: "inline", fontSize: `${font}px` }}>
                    <b> {element.title} {" "}</b>
                    <div
                        style={{ alignContent:"space-between", display: "inline" }}
                        dangerouslySetInnerHTML={{
                            __html: getdisplayText()
                        }}>
                    </div>
                    {"  "} {element.helpLink
                        && (showMore || element.title.length + element.data.length < length)
                        && <a href={element.helpLink}>Help link.</a>} {"    "}
                </div>
                {(element.title.length + element.data.length) > length &&
                    (<IconButton
                        className={classes.button}
                        key="expand"
                        aria-label="expand-message"
                        color="inherit"
                        onClick={() => setShowMore(!showMore)}
                    >
                        {showMore ? <ExpandLessIcon style={{ align: "right", fontSize: `${font}` }} /> : <ExpandMoreIcon style={{ align: "right", fontSize: `${font}` }} />}
                    </IconButton>)
                }
            </Box>
    );
};

export default ReadMore;