const Notifications = {
    AllNotifications: [],
    DisabledKeys: [],
};

export function getfilteredNotification() {
    return Notifications.AllNotifications.filter(function (itm) {
        return Notifications.DisabledKeys.indexOf(itm.key) < 0;
    });
};

export default Notifications;